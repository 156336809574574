import request from "./request";
import crewordRequest from "./crewordRequest";

// ai成图
export function prompt(data) {
  return crewordRequest({
    url: "/ai/prompt?prompt=" + data.value + "&type=" + data.type,
    method: "get",
  });
}

export function findById(data) {
  return crewordRequest({
    url: "/ai/findById?id=" + data,
    method: "get",
  });
}
export function remainingQuantity() {
  return crewordRequest({
    url: "/ai/remainingQuantity",
    method: "get",
  });
}

export function pageList(data) {
  return crewordRequest({
    url: "/ai/pageList",
    method: "post",
    data,
  });
}

// 例子
export function exampleList() {
  return crewordRequest({
    url: "/ai/exampleList",
    method: "post",
  });
}
