<template>
  <div class="history">
    <div class="search_box">
      <div class="back" @click="$emit('back')">
        <i class="el-icon-arrow-left"></i> 返回
      </div>
      <div class="search">
        <input class="inp" v-model="searchValue" type="text" maxlength="75" />
        <!-- <div class="search_btn" v-if="turn" @click="handleClick">
        
            <img :src="require('assets/images/ai_ch.png')" alt="" />
            <img :src="require('assets/images/ch.png')" alt="" />
          </div> -->
        <div class="search_btn" @click="findPageList">
          <!-- <i class="search_icon"></i> -->
          <!-- <img :src="require('assets/images/idiom.png')" alt="" /> -->
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="ai content">
        <div
          v-show="cuuentImage.length != 0"
          class="images"
          v-for="(item, index) in cuuentImage"
          :key="index"
        >
          <div class="title">
            {{ item.key + "（成语AI绘画）" }}
            {{ item.time[0] }}
          </div>
          <div class="option" v-if="item.status == 2">
            <div class="name">AI作画{{ index + 1 }}</div>
            <div class="option_items">
              <div class="view" @click="view(item.content)">
                <img :src="require('assets/images/view.png')" alt="" />查看
              </div>
              <div class="inset" @click="insertBase64(item.content, index)">
                <img :src="require('assets/images/add_cy.png')" alt="" />复制
              </div>
              <div class="download" @click="DownloadBase64Image(item)">
                <img
                  :src="require('assets/images/report-down-blue.png')"
                  alt=""
                />
                下载
              </div>
            </div>
          </div>
          <div class="tips" v-if="item.content == ''">
            {{ item.status == 1 ? "图片生成中" : "图片生成失败" }}
          </div>
          <img
            class="replaceImg"
            :src="require('assets/images/AiLoad.png')"
            v-show="item.status == 1"
            alt=""
          />
          <img
            v-if="item.status == 2 && item.content != ''"
            :src="item.content"
            :id="'image_' + index"
            alt=""
          />
          <img
            v-if="item.status == 2 && item.content == ''"
            :src="require('assets/images/loadError.png')"
            alt=""
          />
          <img
            class="replaceImg"
            :src="require('assets/images/loadError.png')"
            v-if="item.status == 0 || item.status == 3"
            alt=""
          />
        </div>
        <div class="kong" v-show="cuuentImage.length == 0">
          <img :src="require('assets/images/sstp.png')" alt="" />
          <div>暂无历史数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pageList } from "api/searchImage";
import qs from "qs";
import html2canvas from "html2canvas";
export default {
  created() {
    this.findPageList();
  },
  mounted() {
    let searchImage = document.querySelector(".ai.content");
    console.log(searchImage);
    let _this = this;
    searchImage.onscroll = function (e) {
      if (_this.turn) {
        return;
      }
      if (
        searchImage.scrollHeight - searchImage.scrollTop ===
        searchImage.clientHeight
      ) {
        _this.page++;
        _this.findPageList(1);
      }
    };
  },
  data() {
    return {
      currentIndex: 0,
      tableData: [],
      sidebarList: [],
      searchValue: "",
      cuuentImage: [],
      page: 1,
    };
  },
  methods: {
    view(item) {
      this.$emit("viewImage", item);
    },

    insertBase64(item, index) {
      html2canvas(document.getElementById("image_" + index)).then(
        async (canvas) => {
          const data = await fetch(item);
          const blob = await data.blob();

          await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        }
      );

      this.$message.success("复制成功");
    },

    DownloadBase64Image(item) {
      // console.log(this.cuuentImage.key);
      const name = item.content.split("/").pop();
      const suffix = name.substring(name.indexOf("."));
      console.log(item.key + suffix);
      // try {
      //   // eslint-disable-next-line
      //   DownloadImage(item.key + suffix, item.content);
      // } catch (e) {
      //   console.log(e);
      // }
      window.open(item.content + "?attname=" + item.key + suffix);
    },
    handleClick(key, index) {
      this.currentIndex = index;
      this.tableData.forEach((item) => {
        if (item.id == key.id) {
          this.cuuentImage = item;
        }
      });
    },
    // load(){
    //   this.page++;
    //   this.findPageList()
    // },
    async findPageList(flag) {
      const data = {
        page: this.page,
        limit: 10,
        sourceKey: this.searchValue,
      };
      const res = await pageList(qs.stringify(data));
      if (res.data.data.length != 0) {
        console.log(res.data);
        this.sidebarList = res.data.data.map((item) => {
          // return item.sourceKey;
          return {
            id: item.id,
            sourceKey: item.sourceKey,
          };
        });
        this.tableData = res.data.data.map((item) => {
          if (item.response) {
            // let _temp = item.response.split(",");
            // _temp = _temp.map((item) => {
            //   return "https://fafs.antiplagiarize.com/" + item;
            // });
            return {
              id: item.id,
              key: item.sourceKey,
              content: "https://fafs.antiplagiarize.com/" + item.response,
              status: item.status,
              time: item.createTime.split("T"),
            };
          } else {
            return {
              id: item.id,
              key: item.sourceKey,
              content: "",
              status: item.status,
              time: item.createTime.split("T"),
            };
          }
        });
        if (flag == 1) {
          this.cuuentImage = this.cuuentImage.concat(this.tableData);
        } else {
          this.cuuentImage = this.tableData;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* prettier-ignore */
.history {
  height: calc(100vh - 92PX);
  width: 100%;
  .search_box {
    display: flex;
    align-items: center;
    padding: 20PX 0;
    width: 80%;
    margin: 0 auto;
    .back {
      cursor: pointer;
      margin-right: 15PX;
    }
    .search {
      flex: 1;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // padding: 5PX 5PX 5PX 0;

      border-radius: 3PX;
      border: 1PX solid #ff6600;

      .search_left {
        .el-select {
          ::v-deep .el-input {
            .el-input__inner {
              width: 100PX;
              border: 0PX;
            }
          }
        }
      }

      .inp {
        height: 26PX;
        border-left: 1PX solid #e4e4e4;
        padding-left: 20PX;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-default;
        flex: 1;
      }
      .search_btn {
        // width: 40PX;
        height: 40PX;
        padding: 0 10PX;
        background: #ff6600;
        // @include backgroundGroup("~assets/images/idiom.png");
        @include flex-center();
        // border-radius: 0 5PX 5PX 0;
        cursor: pointer;
        > img:first-child {
          width: 52PX;
          height: 22PX;
          margin: 0 10PX;
        }
        img:last-child {
          width: 20PX;
          height: 20PX;
        }
        // .search_icon {
        //   width: 17PX;
        //   height: 17PX;
        //   @include backgroundGroup("~assets/images/idiom.jpg");
        // }
      }
    }

    .keyword_box {
      margin-top: 20PX;
      display: flex;

      .keyword_item {
        font-size: 14PX;
        color: #666666;
        margin-right: 20PX;
        cursor: pointer;
      }
    }
  }
  .main {
    display: flex;
    height: calc(100% - 82PX);
    overflow: auto;
  }
  .sidebar {
    width: 190PX;
    border-right: 1PX solid #ccc;
    .is-active {
      border-right: 4PX solid #3683f2;
      color: #3683f2;
    }
    .sidebar_item {
      cursor: pointer;
      height: 50PX;
      line-height: 50PX;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .content {
    flex: 1;
    overflow: auto;
    padding: 0PX 100PX;
    display: flex;
    flex-wrap: wrap;
    .kong {
      margin: 20vh 0;
      width: 100%;
      text-align: center;
      font-size: 14PX;
      color: #999;
      img {
        width: 150PX;
        height: 150PX;
      }
    }
    .images {
      width: 41%;
      position: relative;
      margin: 4%;
      text-align: center;
      background: #f1f1f1;
      border-radius: 7PX;
      img {
        width: 100%;
        border-radius: 0PX 0PX 7PX 7PX;
      }
      .title {
        text-align: center;
        font-size: 16PX;
        height: 50PX;
        line-height: 50PX;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .replaceImg {
        width: 50%;
        margin: 0 auto;
      }
      .tips {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20%;
        font-size: 16PX;
        color: #999;
      }

      .option {
        padding: 0 0 0 20PX;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        height: 0PX;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s;
        background-color: rgba($color: #000000, $alpha: 0.7);
        overflow: hidden;
        opacity: 0;
        color: #409eff;
        .option_items {
          display: flex;
          align-items: center;
          > div {
            margin: 0 10PX;
            display: flex;
            align-items: center;
          }
        }
        .name {
          color: #fff;
        }
        .view {
          // background: #409eff;
          color: #409eff;
        }
        .inset {
          // background: #409eff;
          color: #409eff;
        }
        > div {
          // background: #fff;
          display: flex;
          align-items: center;
          img {
            width: 16PX;
            height: 16PX;
          }
          cursor: pointer;
          // width: 40PX;
          // height: 40PX;
          font-size: 14PX;
          // text-align: center;
          // line-height: 40PX;
          // border-radius: 50%;
        }
      }
      &:hover .option {
        height: 40PX;
        opacity: 1;
      }
    }
  }
}
</style>
