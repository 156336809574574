<template>
  <div class="mask" v-if="isShow">
    <div class="aiTips">
      <div class="header">
        <img :src="require('assets/images/ai-chentu.png')" alt="" />
      </div>
      <div class="count">
        <span class="text">
          {{ aiCount.tips || "您的免费次数已用完" }}
        </span>
      </div>
      <div class="tips">
        <div>
          本次操作需扣除
          <span style="color: #ff9000">1</span>
          成语券
        </div>
        ( 总剩余
        <span style="color: #ff9000">{{ aiCount.payedQuantity }}</span> 成语券)
        <!-- <div>购买<span style="color: #ff9000">AI绘画字符包</span></div> -->
      </div>

      <div class="goUpLevel">
        <div class="buyCount" @click="buyCount">购买成语券</div>
        <div class="buyVIP" @click="buyVIP" v-show="aiCount.payedQuantity > 0">
          确定
          <!-- {{ data.memberlevel == 1 ? "去购买VIP/SVIP" : "去购买SVIP" }} -->
        </div>
      </div>
      <div class="remember" v-show="aiCount.payedQuantity > 0">
        <el-checkbox
          v-model="remember"
          label="当成语券充足的时候直接扣除,不再提示"
        ></el-checkbox>
      </div>
      <div class="close" @click="$emit('UpLevelAfter')">
        <img :src="require('assets/images/ai-close.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "utils/auth";
import { amendUpdate } from "api/service";
import { remainingQuantity } from "api/searchImage";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    aiCount: {
      type: Object,
      default: () => {},
    },
    setting: {
      type: Object,
      default: () => {},
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // this.getSettings();
    this.remember = this.setting.aiImg == 1 ? true : false;
  },
  data() {
    return {
      remember: false,
      timer: null,
    };
  },
  methods: {
    buyCount() {
      window.open("https://www.writemall.com/details/973?isHeaderShow=false");
      this.timer = setInterval(async () => {
        const res = await remainingQuantity();
        if (res.code == 200) {
          if (res.data.payedQuantity > this.aiCount.payedQuantity) {
            this.$emit("buyAfter", res.data);
            clearInterval(this.timer);
            this.buyVIP()
          }
        }
      }, 4000);
    },
    async buyVIP() {
      this.$emit("aiImage");
      // window.open("https://www.writemall.com/details/1");
      const remember = this.remember ? 1 : 0;
      await amendUpdate({ aiImg: remember });
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
}
.aiTips {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 387px;
  height: 360px;
  background-image: url("~@/assets/images/ai-tips-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .close {
    background: #fff;
    position: absolute;
    bottom: -50px;
    width: 28px;
    height: 28px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .header {
    width: 132px;
    height: 93px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    width: 100%;
    font-weight: bold;
    img {
      margin: 0 10px;
      width: 32px;
      height: 32px;
    }
    font-size: 24px;
    color: #333335;
  }
  .tips {
    color: #666666;
    font-size: 16px;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    line-height: 30px;
  }
  .remember {
    font-size: #fff;
    position: absolute;
    bottom: 5%;
    left: 55%;
    transform: translateX(-50%);
    width: 100%;
  }
  .goUpLevel {
    font-size: #fff;

    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    div {
      // flex: 1;
      text-align: center;
      margin: 0 10px;
      cursor: pointer;
    }
    .buyCount {
      width: 129px;
      height: 40px;
      line-height: 40px;
      border: 1px #ff9900 solid;
      border-radius: 6px;
    }
    .buyVIP {
      width: 129px;
      height: 40px;
      background: #fff;
      color: #fff;
      border: 1px #ff9900 solid;
      border-radius: 6px;
      background: #ff9900;
      line-height: 40px;
    }
  }
}
</style>