<template>
  <div class="AiImageS">
    <div class="rankTips" v-show="showEndBtn">
      <!-- prettier-ignore -->
      <el-carousel arrow="never" indicator-position="none" style="width: 200PX">
        <el-carousel-item v-for="(item, index) in exampleList" :key="index">
          <div class="content">
            <div class="left">
              <div class="title">{{ item.key }}</div>
              <img
                :src="'https://fafs.antiplagiarize.com/' + item.url"
                alt=""
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="text">
        <div class="tips1">图片正在生成请耐心等待....</div>
        <div class="tips2">{{ rankTips[1] || "请稍后..." }}</div>
        <div class="end" @click="endAI">终止绘画</div>
        <div class="text—tips">
          <div>温馨提示：不想等待？可以直接返回。</div>
          <div>
            生成的作品可以在<span
              style="color: #4587ff; cursor: pointer"
              @click="handleClickHistory"
              >"我的画集"</span
            >中查看结果
          </div>
        </div>
      </div>
    </div>
    <div class="main loadingMask" v-show="!isShowHistory">
      <!-- <div class="search_box">
        <div class="search">
          <el-select v-model="selectType" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <input
            class="inp"
            v-model="searchValue"
            type="text"
            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="handleClick(1)"
            :placeholder="placeholderText"
            maxlength="75"
          />
          <div class="search_btn" @click="handleClick(1)">
            <img :src="require('assets/images/ch.png')" alt="" />
          </div>
        </div>
        <div class="history" @click="handleClickHistory">
          <img :src="require('assets/images/ai_history.png')" alt="" />
        </div>
      </div> -->

      <div class="content">
        <div class="main_page" v-if="aiImgArr.length == 0">
          <!-- 画面描述 -->
          <div class="input">
            <div class="title">
              <img :src="require('assets/images/shuru.png')" alt="" />
              <span>请输入画面描述</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="格式：修饰语+主体+场景，越详细越好哦，建议20字以上，请不要输入色情涉恐涉政,否则不予展示，严重会被封号哦"
                placement="right"
                ><img
                  class="wenhao"
                  :src="require('assets/images/gary_wenhao.png')"
                  alt=""
                />
              </el-tooltip>

              <span class="text_num">({{ count }}/200字数)</span>
            </div>
            <div class="shuru">
              <textarea
                v-model="searchValue"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="textareaInput"
                @keyup.enter="handleClick(1)"
                :placeholder="placeholderText"
                maxlength="200"
              ></textarea>
            </div>
          </div>
          <!-- 灵感示例 -->
          <div class="inspiration">
            <div class="title">
              <img :src="require('assets/images/linggan.png')" alt="" />
              <span>灵感示例</span>
            </div>
            <div class="inspiration_content">
              <div
                class="item"
                :class="{ exampleActive: exampleIndex == index }"
                v-for="(item, index) in example_img"
                :key="index"
                @click="inspirationClick(item, index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <!-- 风格类型 -->
          <div class="typeClass">
            <div class="title">
              <img :src="require('assets/images/fengge.png')" alt="" />
              <span>风格类型</span>
            </div>
            <div class="typeClass_content">
              <div
                class="item"
                :class="{ exampleActive: typeClassIndex == index }"
                v-for="(item, index) in styleList"
                :key="index"
                @click="typeClassClick(item, index)"
              >
                <img :src="item.imgSrc" alt="" />
                <div class="theme">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <!-- 参考词条 -->
          <div class="reference">
            <div class="title">
              <img :src="require('assets/images/cankao.png')" alt="" />
              <span
                >参考词条 <span class="text_tips">(点击可加入文本框)</span>
              </span>
            </div>
            <div class="reference_content">
              <div
                class="item"
                @click="referenceClick(item)"
                v-for="(item, index) in entrylist"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <!-- 操作 -->
          <div class="options">
            <div class="startImg" @click="handleClick(1)">
              <img :src="require('assets/images/ch.png')" alt="" />
              开始绘画
            </div>
            <div class="historyImg" @click="handleClickHistory">
              <img :src="require('assets/images/ai_history.png')" alt="" />
              我的画集
            </div>
          </div>
        </div>

        <div class="imgList" v-else>
          <div class="aiImg_generate">
            <div
              class="aiImg_item"
              v-for="(item, index) in aiImgArr"
              :key="index"
            >
              <div class="option">
                <div class="name">AI作画{{ index + 1 }}</div>
                <div class="option_items">
                  <div class="view" @click="view(item)">
                    <img :src="require('assets/images/view.png')" alt="" />查看
                  </div>
                  <div class="inset" @click="insertBase64(item, index)">
                    <img
                      :src="require('assets/images/add_cy.png')"
                      alt=""
                    />复制
                  </div>
                  <div class="download" @click="DownloadBase64Image(item)">
                    <img
                      :src="require('assets/images/report-down-blue.png')"
                      alt=""
                    />
                    下载
                  </div>
                </div>
              </div>
              <img :src="item.response" :id="'image_' + index" alt="" />
            </div>
            <!-- 操作 -->
            <div class="options" v-show="aiImgArr[0] != ''">
              <div class="startImg" @click="aiImgArr = []">
                <img :src="require('assets/images/ch.png')" alt="" />
                AI绘画
              </div>
              <div class="historyImg" @click="handleClickHistory">
                <img :src="require('assets/images/ai_history.png')" alt="" />
                我的画集
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <History
      @viewImage="viewImageChild"
      @back="back"
      ref="History"
      v-if="isShowHistory"
    />
    <AiTips
      :data="currentTips"
      :aiCount="aiCount"
      :isShow="isShow"
      :setting="setting"
      @aiImage="aiImage"
      @buyAfter="buyAfter"
      @UpLevelAfter="UpLevelAfter"
    />
    <el-dialog title="预览" class="viewImage" :visible.sync="viewImage">
      <img :src="viewImagePath" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import History from "./historyAi";
import { getToken } from "utils/auth";
import { createAiLoading } from "utils/utils";
import { settings } from "api/service";
import {
  prompt,
  findById,
  remainingQuantity,
  exampleList,
} from "api/searchImage";
import AiTips from "components/AiTips.vue";
import html2canvas from "html2canvas";
export default {
  components: {
    History,
    AiTips,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  created() {
    this.initExampleList();
    this.getTimeImg();
    this.getSettings();
  },
  data() {
    return {
      exampleIndex: -1,
      typeClassIndex: 0,
      sidebarList: [],
      currentTips: {},
      isShow: false,
      isShowHistory: false,
      count: 0,
      searchValue: "",
      checked: false,
      currentItem: "图片推荐",
      imagesData: [],
      currentKey: "",
      viewImage: false,
      viewImagePath: "",
      page: 1,
      size: 10,
      currtentItem: "",
      placeholderText: "输入关键词，AI生成相应关键词的图片",
      turn: false,
      isShowAi: false,
      aiImgArr: [],
      timer: null,
      loading: null,
      showEndBtn: false,
      rankTips: [],
      TimeImg: 0,
      countdownTime: null,
      aiCount: {},
      setting: {},
      exampleList: [],
      selectType: 0,
      style: "",
      typeList: [
        {
          label: "山水画",
          value: 1,
        },
        {
          label: "现代画",
          value: 0,
        },
      ],
      styleList: [
        {
          name: "现代画",
          type: 0,
          desc: "",
          imgSrc: "https://mall.writemall.com/images/wxminimg/xiandaihua.png",
        },
        {
          name: "山水画",
          type: 1,
          imgSrc: "https://mall.writemall.com/images/wxminimg/shanshuihua.png",
        },

        {
          name: "二次元",
          type: 0,
          desc: ",二次元风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/erciyuan.png",
        },

        {
          name: "水彩画",
          type: 0,
          desc: ",水彩画风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/shuicaihua.png",
        },
        {
          name: "卡通画",
          type: 0,
          desc: ",卡通画风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/katonghua.png",
        },
        {
          name: "动漫",
          type: 0,
          desc: ",动漫风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/dongmanfeng.png",
        },
        {
          name: "油画",
          type: 0,
          desc: ",油画风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/youhua.png",
        },
        {
          name: "素描",
          type: 0,
          desc: ",素描风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/sumiao.png",
        },
        {
          name: "写实",
          type: 0,
          desc: ",写实风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/xieshifeng.png",
        },
        {
          name: "赛博朋克",
          type: 0,
          desc: ",赛博朋克风格",
          imgSrc: "https://mall.writemall.com/images/wxminimg/sabopengke.png",
        },
      ],
      entrylist: [
        "高清",
        "插图",
        "极致细节",
        "电影照明",
        "色彩鲜艳",
        "近景",
        "中景",
        "暖色调",
        "暖光",
        "彩色光源",
        "真实光源",
        "长发",
        "卷发",
        "短发",
        "金发",
        "白发",
        "黑发",
        "凌乱的发丝",
        "公主裙",
        "风衣",
        "牛仔裤",
        "耳坠",
        "美丽细致的星空",
        "户外",
        "海滩",
        "房间内",
        "床上",
        "花",
        "魔法环",
        "青山绿水",
        "足球场",
        "篮球场",
        "麦田",
        "血月",
        "火车上",
        "树",
      ],
      example_img: [
        {
          title: "女孩",
          content: "戴贝雷帽的女孩，紫色的长发，手拿黄色气球，在海边奔跑",
        },
        {
          title: "机甲兔子",
          content:
            "钛合金，机甲兔子，赛博朋克，未来感，设计作品，摄影棚光照, 未来主义",
        },
        {
          title: "书桌",
          content:
            "抹茶味奶茶，古香书桌，安静的环境，落地窗外天清云淡,写实主义",
        },
        {
          title: "飞船",
          content: "科幻外星人飞船飞行，暴风雨的背景，电影闪电",
        },

        {
          title: "山间流水",
          content:
            "青绿色草地连成一片，山间起伏，有倾泻的流水，星星点缀天空，蓝天白云，天色泛白，巨幅画面感，遥拍视角，4K，高清",
        },
        {
          title: "长城",
          content:
            "红色灯笼，长城，秋天，高清，奇幻风格，史诗感，艺术站，超现实主义",
        },
        {
          title: "仙境",
          content:
            "仙境，高山流水，白色青色，悬浮，宝石，佛教，明亮，早在黎明：中国南朝系画，日系动漫山水画，独特的画种 ，是天生爱自然的精华，天地的秀气，所以阴阳、虽然晴雨、寒暑、朝昏、夜有无穷妙的到。杰作超清晰，高分辨率，光影变化，体积光，都市，历史。4k，高清，日系，动漫，山水画。",
        },
        {
          title: "油画女孩",
          content:
            "杰作，油画（中等），最好的质量，最好的品质，惊人的，美丽的细节眼睛，精细的细节，景深，杰作，1女孩，黑发，腮红，眼影，尖尖的耳朵，轻颗粒",
        },
        {
          title: "泰迪熊",
          content: "泰迪熊穿着西服在时代广场跳舞，细节清晰，高清",
        },
        {
          title: "怪物",
          content:
            "一个发光的人形蒲公英怪物，大眼睛发光，被火红的橙色包围，高度详细，数字艺术，锐利的焦点，艺术站趋势，朝鲜蓟，动漫艺术风格",
        },
      ],
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    // this.$bus.$off("selectionText"); //注销事件
  },

  methods: {
    handleImg() {
      window.open("https://www.writemall.com/details/972");
    },
    aiImage() {
      this.isShow = false;
      this.handleClick(2);
    },
    buyAfter(data) {
      // this.getTimeImg();
      this.aiCount = data;
    },
    async getTimeImg() {
      const res = await remainingQuantity();
      if (res.code == 200) {
        this.aiCount = res.data;
        this.TimeImg = res.data.freeQuantity + res.data.payedQuantity;
      }
    },
    inspirationClick(item, index) {
      this.searchValue = item.content;
      this.count = this.searchValue.length;
      this.exampleIndex = index;
    },
    typeClassClick(item, index) {
      this.typeClassIndex = index;
      this.selectType = item.type;
      this.style = item.desc;
    },
    referenceClick(item) {
      this.searchValue = this.searchValue + " " + item;
      this.count = this.searchValue.length;
    },
    getSelectionText() {
      // props为异步 改变值的第一时间并不能拿到最新值 , 所以使用setTimeout
      setTimeout(() => {
        this.searchValue = this.content;
        this.handleClick(1);
      });
    },
    async getSettings() {
      const res = await settings();
      if (res.code == 200) {
        this.setting = res.data;
        // this.remember = res.data.aiImg == 1 ? true : false;
      }
    },
    endAI() {
      this.aiImgArr = [];
      if (this.loading) {
        this.loading.close();
      }
      clearInterval(this.timer);
      clearInterval(this.countdownTime);
      this.showEndBtn = false;
    },
    viewImageChild(item) {
      this.viewImagePath = item;
      this.viewImage = true;
    },

    async initExampleList() {
      const res = await exampleList();
      if (res.code == 200) {
        this.exampleList = res.data;
      }
    },
    handleClickHistory() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.endAI();
      this.currentItem = "历史AI画作";
      this.isShowHistory = true;
      this.$refs["History"].findPageList();
    },
    aiRecommendClick() {
      this.currentItem = "图片推荐";
      this.turn = false;
      this.isShowHistory = false;
      this.isShowAi = false;
      // console.log(11);
      try {
        // eslint-disable-next-line
        RecommendImg();
      } catch (e) {
        console.log(e);
      }
    },
    textareaInput() {
      this.count = this.searchValue.length;
    },
    //插入图片
    inset(item) {
      try {
        // eslint-disable-next-line
        InsertImage(item.middleURL);
      } catch (e) {
        console.log(e);
      }
    },
    UpLevelAfter() {
      this.isShow = false;
    },
    back() {
      this.isShowHistory = false;
    },
    view(item) {
      this.viewImagePath = item.response;
      this.viewImage = true;
    },
    insertBase64(item, index) {
      html2canvas(document.getElementById("image_" + index)).then(
        async (canvas) => {
          const data = await fetch(item.response);
          const blob = await data.blob();

          await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        }
      );
      this.$message.success("复制成功");
    },

    DownloadBase64Image(item) {
      console.log(item);
      const name = item.response.split("/").pop();
      const suffix = name.substring(name.indexOf("."));
      // searchValue
      console.log(item.sourceKey + suffix);
      // try {
      //   // eslint-disable-next-line
      //   DownloadImage(item.sourceKey + suffix, item.response);
      // } catch (e) {
      //   console.log(e);
      // }
      window.open(item.response);
    },
    handleFocus() {
      this.placeholderText = "";
    },
    handleBlur() {
      this.placeholderText = "输入关键词，AI生成相应关键词的图片";
    },
    switchChangeOther() {
      this.currentItem = "AI绘画";
      this.isShowAi = true;
      this.isShowHistory = false;
      this.turn = true;
    },
    viewOnline(item) {
      this.viewImagePath = item.middleURL;
      this.viewImage = true;
    },
    download(item) {
      console.log(item.fromPageTitleEnc + "." + item.type);
      console.log(item.middleURL);
      try {
        // eslint-disable-next-line
        DownloadImage(item.fromPageTitleEnc + "." + item.type, item.middleURL);
      } catch (e) {
        console.log(e);
      }
    },
    async handleClick(flag = 1) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return;
      }
      if (this.searchValue == "") {
        return this.$message.error("请输入搜索框内容");
      }
      this.currentKey = this.searchValue;
      this.currentTips = {};
      this.currtentItem = "";

      try {
        if (
          this.aiCount.freeQuantity == 0 &&
          this.setting.aiImg == 0 &&
          flag == 1
        ) {
          this.isShow = true;
          return;
        }
        // this.loading = createAiLoading(".loadingMask");
        // this.aiImgArr.push("");
        this.aiImgArr = []; //清空当前生成的图片
        this.aiImgArr.push(""); // 隐藏缺省页
        this.showEndBtn = true;
        const params = {
          value: this.searchValue + this.style,
          type: this.selectType,
        };
        console.log();
        const res = await prompt(params);
        if (res.code == 200) {
          this.getTimeImg();
          // this.rankTips = res.data.messageData.split(",");
          let tips = res.data.messageData.split(",");
          let countdown = res.data.countdownTime;
          let minutes = 0;
          let seconds = 0;
          if (countdown * 1 < 60) {
            seconds = countdown;
          } else {
            minutes = parseInt(countdown / 60);
            seconds = countdown % 60;
          }
          console.log(countdown, minutes, seconds);
          if (minutes == 0) {
            tips[1] = `预计需要${seconds}秒时间。`;
          } else {
            tips[1] = `预计需要${minutes}分${seconds}秒时间。`;
          }

          this.rankTips = tips;
          // seconds
          this.countdownTime = setInterval(() => {
            console.log(minutes, seconds);
            if (seconds >= 2) {
              seconds--;
            } else {
              if (minutes <= 0) {
                clearInterval(this.countdownTime);
                seconds--;
              } else {
                minutes--;
                seconds = 59;
              }
            }
            // this.rankTips[1] = `预计需要${minutes}分${seconds}秒时间。`;
            if (minutes == 0) {
              this.$set(this.rankTips, 1, `预计需要${seconds}秒时间。`);
            } else {
              this.$set(
                this.rankTips,
                1,
                `预计需要${minutes}分${seconds}秒时间。`
              );
            }
          }, 1000);

          this.timer = setInterval(async () => {
            const result = await findById(res.data.id);
            if (result.data.status == 2) {
              const _temp = result.data.messageCount;
              // 本次AI作画预计需要30秒时间
              // this.loading.close();
              this.showEndBtn = false;
              // this.aiImgArr = _temp.map((item) => {
              //   return "https://fafs.antiplagiarize.com/" + item;
              // });
              result.data.response =
                "https://fafs.antiplagiarize.com/" + result.data.response;

              this.aiImgArr = [result.data];

              if (this.aiImgArr.length != 0) {
                this.isShowAi = true;
              }
              clearInterval(this.timer);
              clearInterval(this.countdownTime);
            } else if (result.data.status == 3) {
              // this.loading.close();
              clearInterval(this.timer);
              clearInterval(this.countdownTime);
              this.showEndBtn = false;
              this.aiImgArr = [];
              this.$message.error("生成失败!");
            }
          }, 8000);
        } else if (res.code == 8302) {
          this.aiImgArr = [];
          // this.$message.error(res.message);
          // this.loading.close();
          this.showEndBtn = false;
          this.currentTips = res.data;
          this.isShow = true;
        } else {
          this.aiImgArr = [];
          // this.loading.close();
          this.showEndBtn = false;
          this.$message.error(res.message);
        }

        // this.aiImgArr = res.data.content;
      } catch (e) {
        this.aiImgArr = [];
        // this.loading.close();
        this.showEndBtn = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/* prettier-ignore */
.AiImageS {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 40PX;
  .viewImage {
    ::v-deep .el-dialog {
      width: 700PX;
      .el-dialog__body {
        img {
          width: 100%;
        }
      }
    }
  }
  .rankTips {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    z-index: 9999;
    font-size: 14PX;
    color: #333;
    line-height: 25PX;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 5PX 3PX #ccc;
    border-radius: 8PX;
    width: 800PX;
    padding: 15PX;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .text {
      div {
        margin-bottom: 10PX;
      }
      .text—tips {
        color: #999;
        font-size: 12PX;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .left {
        img {
          width: 250PX;
          height: 250PX;
        }
        .title {
          color: #000000;
          margin-bottom: 20PX;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // height: 30PX;
        }
      }
    }
  }
  .end {
    width: 80PX;
    height: 30PX;
    line-height: 30PX;
    text-align: center;
    background: #409eff;
    color: #fff;
    margin: 10PX auto;
    font-size: 14PX;

    border-radius: 5PX;

    cursor: pointer;
  }
  .main {
    // flex: 1;
    width: 100%;
    height: 87vh;

    overflow: auto;

    .type {
      width: 80%;
      margin: 0 auto;
    }
    .content {
      display: flex;
      width: 100%;
      padding: 0 20PX;
      box-sizing: border-box;
      .main_page {
        // width: 100%;
        flex: 1;
        .input {
          width: 100%;
          .title {
            display: flex;
            width: 250PX;
            align-items: center;
            justify-content: space-between;
            font-size: 15PX;
            img {
              width: 16PX;
              height: 16PX;
            }
            i {
              cursor: pointer;
            }
            .wenhao {
              cursor: pointer;
            }
            .text_num {
              color: #999;
            }
          }
          .shuru {
            margin-top: 20PX;
            textarea {
              box-sizing: border-box;
              resize: none;
              background: #fff5ef;
              border: 1PX solid #ff6600;
              border-radius: 5PX;
              width: 100%;
              height: 70PX;
              padding: 10PX;
              font-size: 14PX;
            }
          }
        }
        .inspiration {
          margin-top: 20PX;
          .title {
            display: flex;
            width: 85PX;
            align-items: center;
            justify-content: space-between;
            font-size: 15PX;
            img {
              width: 16PX;
              height: 16PX;
            }
          }
          .inspiration_content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10PX;
            .item {
              cursor: pointer;
              margin-right: 10PX;
              margin-top: 10PX;
              padding: 8PX 10PX;
              border: 1PX solid #ccc;
              border-radius: 18PX;
            }
            .exampleActive {
              border: 1PX solid transparent;
              border-radius: 999PX;
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  325deg,
                  #ff6900 -6.01%,
                  #f1b87e 33.34%,
                  #dfa755 48.66%,
                  #3a8ee6 64.54%,
                  #1f81ee 83.26%
                );
              background-origin: border-box;
              background-clip: padding-box, border-box;
            }
          }
        }
        .typeClass {
          margin-top: 20PX;
          .title {
            display: flex;
            width: 85PX;
            align-items: center;
            justify-content: space-between;
            font-size: 15PX;
            img {
              width: 16PX;
              height: 16PX;
            }
          }
          .typeClass_content {
            display: flex;
            margin-top: 10PX;
            flex-wrap: wrap;
            .item {
              width: 75PX;
              height: 75PX;
              border-radius: 8PX;
              margin: 5PX;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              cursor: pointer;
              img {
                border-radius: 8PX;
                width: 70PX;
                height: 70PX;
              }
              .theme {
                position: absolute;
                background-color: rgba($color: #000000, $alpha: 0.3);
                width: 70PX;
                color: #fff;
                text-align: center;
                font-size: 14PX;
                border-radius: 0PX 0PX 8PX 8PX;
                bottom: 5PX;
              }
            }
            .exampleActive {
              border: 1PX solid transparent;
              // border-radius: 999PX;
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  325deg,
                  #ff6900 -6.01%,
                  #f1b87e 33.34%,
                  #dfa755 48.66%,
                  #3a8ee6 64.54%,
                  #1f81ee 83.26%
                );
              background-origin: border-box;
              background-clip: padding-box, border-box;
            }
          }
        }
        .reference {
          margin-top: 20PX;
          .title {
            display: flex;
            width: 225PX;
            align-items: center;
            justify-content: space-between;
            font-size: 15PX;
            .text_tips {
              color: #ccc;
            }
            img {
              width: 16PX;
              height: 16PX;
            }
          }
          .reference_content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10PX;
            .item {
              cursor: pointer;
              margin-right: 10PX;
              margin-top: 10PX;
              padding: 8PX 10PX;
              border: 1PX solid #ccc;
              border-radius: 5PX;
            }
          }
        }
        .options {
          // position: absolute;
          // bottom: 180PX;
          // left: 50%;
          // transform: translateX(-50%);
          margin: 60PX auto 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50%;

          .startImg {
            background: linear-gradient(to right, #ff6600, #f89e61, #f1c5a7);
            border-radius: 20PX 0PX 0PX 20PX;
            height: 40PX;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 15PX;
            width: 48%;
            cursor: pointer;
            img {
              width: 18PX;
              height: 18PX;
            }
          }
          .historyImg {
            background: linear-gradient(to right, #acd0f5, #75baff, #409eff);
            border-radius: 0PX 20PX 20PX 0PX;
            height: 40PX;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 15PX;
            width: 48%;
            cursor: pointer;
            img {
              width: 18PX;
              height: 18PX;
            }
          }
        }
      }

      .imgList {
        flex: 1;

        .texxt {
          margin-top: 10PX;
          color: #999;
          text-align: center;
          font-size: 12PX;
        }
        .aiImg_generate {
          display: flex;
          flex-direction: column;
          width: 60%;
          margin: 0 auto;
          .options {
            // position: absolute;
            // bottom: 180PX;
            // left: 50%;
            // transform: translateX(-50%);
            margin: 20PX auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;

            .startImg {
              background: linear-gradient(to right, #ff6600, #f89e61, #f1c5a7);
              border-radius: 20PX 0PX 0PX 20PX;
              height: 40PX;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 15PX;
              width: 48%;
              cursor: pointer;
              img {
                width: 18PX;
                height: 18PX;
              }
            }
            .historyImg {
              background: linear-gradient(to right, #acd0f5, #75baff, #409eff);
              border-radius: 0PX 20PX 20PX 0PX;
              height: 40PX;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 15PX;
              width: 48%;
              cursor: pointer;
              img {
                width: 18PX;
                height: 18PX;
              }
            }
          }
          .aiImg_item {
            position: relative;
            flex: 1;
            margin: 30PX;
            img {
              width: 100%;
            }
            .option {
              padding: 0 0 0 20PX;
              box-sizing: border-box;
              bottom: 0;
              width: 100%;
              height: 0PX;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: space-between;
              transition: all 0.3s;
              background-color: rgba($color: #000000, $alpha: 0.7);
              overflow: hidden;
              opacity: 0;
              color: #409eff;
              .option_items {
                display: flex;
                align-items: center;
                > div {
                  margin: 0 10PX;
                  display: flex;
                  align-items: center;
                }
              }
              .name {
                color: #fff;
              }
              .view {
                // background: #409eff;
                color: #409eff;
              }
              .inset {
                // background: #409eff;
                color: #409eff;
              }
              > div {
                // background: #fff;
                display: flex;
                align-items: center;
                img {
                  width: 16PX;
                  height: 16PX;
                }
                cursor: pointer;
                // width: 40PX;
                // height: 40PX;
                font-size: 14PX;
                // text-align: center;
                // line-height: 40PX;
                // border-radius: 50%;
              }
            }
            &:hover .option {
              height: 40PX;
              opacity: 1;
            }
          }
        }
      }
    }
    .search_box {
      padding: 20PX 0;
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .history {
        width: 40PX;
        height: 40PX;
        background: #4587ff;
        border-radius: 5PX;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20PX;
        cursor: pointer;
        img {
          width: 20PX;
          height: 20PX;
        }
      }
      .search {
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 3PX;
        box-shadow: 0 0 0 1PX #ff6600;
        ::v-deep .el-select {
          width: 90PX;
          .el-input__inner {
            border: none;
          }
        }
        .search_left {
          .el-select {
            ::v-deep .el-input {
              .el-input__inner {
                width: 100PX;
                border: 0PX;
              }
            }
          }
        }

        .inp {
          height: 26PX;
          border-left: 1PX solid #e4e4e4;
          padding-left: 20PX;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-default;
          flex: 1;
        }
        .search_btn {
          // width: 40PX;
          height: 40PX;
          padding: 0 10PX;
          background: #ff6600;
          // @include backgroundGroup("~assets/images/idiom.png");
          @include flex-center();
          // border-radius: 0 5PX 5PX 0;
          cursor: pointer;
          > img:first-child {
            width: 52PX;
            height: 22PX;
            margin: 0 10PX;
          }
          img:last-child {
            width: 20PX;
            height: 20PX;
          }
          // .search_icon {
          //   width: 17PX;
          //   height: 17PX;
          //   @include backgroundGroup("~assets/images/idiom.jpg");
          // }
        }
      }

      .keyword_box {
        margin-top: 20PX;
        display: flex;

        .keyword_item {
          font-size: 14PX;
          color: #666666;
          margin-right: 20PX;
          cursor: pointer;
        }
      }
    }
    .kong {
      display: grid;
      grid-template-columns: repeat(3, 200PX); //同上
      grid-gap: 10PX;
      justify-content: center;
      // flex-direction: column;
      color: #999;
      font-size: 14PX;
      margin: 100PX 0;
      position: relative;
      .tips {
        // text-align: center;
        position: absolute;
        bottom: -40PX;
        left: 45%;
        // transform: translateY(-55%);
      }
      .example {
        height: 200PX;
        position: relative;
        .backdorp {
          position: absolute;
          bottom: 0PX;
          opacity: 0;
          height: 0PX;
          width: 100%;
          transition: all 0.3s;
          padding: 15PX;
          color: #fff;
          box-sizing: border-box;
          background-color: rgba($color: #000000, $alpha: 0.7);
          line-height: 25PX;
        }
        &:hover .backdorp {
          height: 100%;
          opacity: 1;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      // height: 100%;
    }
    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14PX;
      color: #999;
      .text {
        font-size: 16PX;
        font-weight: bold;
        color: #4587ff;
      }
    }
    .images {
      margin-top: 20PX;
      padding: 0 20PX;
      box-sizing: border-box;
      -webkit-column-count: 3; //想要排成的列数
      -webkit-column-gap: 4PX;
      // display: flex;
      // position: relative;
      // flex-direction: column;
      // flex-wrap: wrap;
      // height: calc(100vh - 120PX);
      .item {
        // width: 200PX;
        // height: auto;
        // position: absolute;
        position: relative;
        // overflow: hidden;

        .backgord {
          width: 100%;
          height: 0%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0.6);
          // z-index: ;
          display: flex;
          align-items: center;
          justify-content: center;
          // display: none;
          -webkit-opacity: 0;
          transition: all 0.3s;
          .inset {
            background: #409eff;
            color: #fff;
          }
          .view {
            background: #409eff;
            color: #fff;
          }
          > div {
            cursor: pointer;
            margin: 0 3PX;
            width: 50PX;
            height: 50PX;
            background: #fff;
            border-radius: 50%;
            line-height: 50PX;
            text-align: center;
            // color: #999;
            font-size: 14PX;
          }
        }
        img {
          width: 100%;
          margin: 1PX 0;
          cursor: pointer;
        }
      }
      .item:hover .backgord {
        -webkit-opacity: 1;
        height: 70PX;
      }
    }
  }
}
</style>
